import styled from '@emotion/styled';
import { mediaMax, breakpoints } from 'styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 17px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 26px;
  line-height: 28px;
  text-align: center;

  ${mediaMax(breakpoints.tabletXS)} {
    font-size: 28px;
  }
`;

export const Subtitle = styled.h2`
  font-weight: 400;
  font-size: 16px;
  line-height: 18.2px;
  text-align: center;

  ${mediaMax(breakpoints.tabletXS)} {
    font-size: 14px;
  }
`;
