import { memo, FC } from 'react';
import { useTranslationEditorContext } from 'providers/TranslationEditor';
import { useTranslation } from 'react-i18next';
import PrimaryButton from 'components/ui/PrimaryButton';
import Popup from 'components/ui/Popup';
import Backdrop from 'components/ui/Backdrop';

import { Container, ButtonWrapper, Title, Subtitle } from './styled';

type SubscriptionCancelledPopUpProps = {
  open: boolean;
  isLoading: boolean;
  onClose: () => void;
};

const SubscriptionCancelledPopUp: FC<SubscriptionCancelledPopUpProps> = ({ open, onClose, isLoading }) => {
  const { t } = useTranslation();

  const { getDataAttributes } = useTranslationEditorContext();

  return (
    <>
      <Backdrop open={open} onClick={onClose} />
      <Popup open={open} onClose={onClose}>
        <Container>
          {isLoading ? (
            <Title {...getDataAttributes('qr.cancelSubscription.canceled.title.loading')}>
              {t('qr.cancelSubscription.canceled.title.loading', 'Cancel subscription in process')}
            </Title>
          ) : (
            <Title {...getDataAttributes('qr.cancelSubscription.canceled.title')}>
              {t('qr.cancelSubscription.canceled.title', 'Cancel subscription')}
            </Title>
          )}
          <Subtitle {...getDataAttributes('qr.cancelSubscription.canceled.subtitle')}>
            {t('qr.cancelSubscription.canceled.subtitle', 'Your subscription was cancelled')}
          </Subtitle>
          <ButtonWrapper>
            <PrimaryButton onClick={onClose} {...getDataAttributes('qr.cancelSubscription.canceled.confirmButton')}>
              {t('qr.cancelSubscription.canceled.confirmButton', 'Ok')}
            </PrimaryButton>
          </ButtonWrapper>
        </Container>
      </Popup>
    </>
  );
};

export default memo(SubscriptionCancelledPopUp);
