import MUIBackdrop, { BackdropProps } from '@mui/material/Backdrop';

export default function Backdrop(props: BackdropProps) {
  return (
    <MUIBackdrop
      sx={{ zIndex: 100, backgroundColor: 'rgba(0, 0, 0, 0.85)' }}
      style={
        // MUI starts opacity at 0 to do a transition animation
        // but this makes the modal invisible for bots and for when JS is disabled.
        // Here we use the transition only if this component is being rendered by the browser
        // (eg, if you route-transition here), but will not use it if it's being SSR
        typeof window !== 'undefined' ? {} : { opacity: 1 }
      }
      {...props}
    />
  );
}
