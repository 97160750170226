import styled from '@emotion/styled';
import { mediaMax, breakpoints } from '../../../styles';
import { css } from '@emotion/react';

export const Positioner = styled.div`
  position: fixed;
  z-index: 100;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media screen and (min-width: ${breakpoints.laptopS}px) and (max-height: 700px) {
    transform: translate(-50%, -50%) scale(0.8);
  }

  ${mediaMax(breakpoints.mobileM)} {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Dialog = styled.div<{ withScroll?: boolean; zoomedIn?: boolean }>`
  background-color: var(--qr-global-color-white);
  max-height: 90vh;
  width: 560px;
  display: grid;
  padding: 30px 70px 50px 70px;
  border-radius: 16px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);

  &.QrPreviewPopup {
    width: unset;
    padding: 0 40px;

    ${mediaMax(breakpoints.tabletM)} {
      width: calc(100vw - 40px);
    }
  }

  ${mediaMax(breakpoints.tabletXS)} {
    width: calc(100vw - 40px) !important;
    padding: 20px 15px;
  }

  ${mediaMax(breakpoints.mobileL)} {
    width: calc(100vw - 40px) !important;
    padding: 20px 15px;
  }

  ${({ withScroll }) =>
    withScroll &&
    css`
      overflow-y: auto;
      scrollbar-color: initial;

      &::-webkit-scrollbar-track {
        // Prevent scroll track overflowing popup container's rounded corners
        margin-block: 20px;
      }

      ${mediaMax(breakpoints.mobileL)} {
        max-height: calc(90vh - 40px);
      }
    `}
`;

export const CloseIconContainer = styled.button`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: -32px;
  cursor: pointer;
  gap: 6px;
  color: var(--qr-global-color-white);

  ${mediaMax(breakpoints.tabletXS)} {
    top: -27px;
  }
`;

export const CloseIconText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
`;
